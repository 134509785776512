"use client";
import PropTypes from "prop-types";
import CustomImage from "../../atoms/customImage";
import React, { useEffect, useState } from "react";
import CookieUtil from "../../../utils/storage/cookieUtil";
import { IS_HIDE_APP_BANNER } from "../../../constants/common/common";

/**
 * This component is for app redirection banner to app/play store.
 *
 * @param {object} param0  props passed to the component .
 * @param {string} param0.appBannerData prop for content of app banner.
 * @returns {React.ReactElement} main JSX for the component.
 */
const AppRedirectionBanner = ({ appBannerData }) => {
  const [showAppBanner, setShowAppBanner] = useState(true);
  const { imgHeight, imgWidth, appDeepLink, imageUrl } = appBannerData;
  const paddingTop = (imgHeight / imgWidth) * 100;

  useEffect(() => {
    const header = document.getElementById("header");
    if (header) {
      header.classList.replace("sticky", "relative");
    }
  }, []);

  useEffect(() => {
    const header = document.getElementById("header");
    const appBanner = document.getElementById("appBanner");
    const mainContent = document.getElementById("mainContent");
    /** */
    const handleScroll = () => {
      const bannerThresHold = (paddingTop / 100) * window.innerWidth;
      const scrollY = window.scrollY;
      if (scrollY > Math.round(bannerThresHold)) {
        if (header && showAppBanner) {
          header.classList.replace("relative", "sticky");
          appBanner.classList.replace("relative", "fixed");
          mainContent.style.marginTop = `${Math.round(bannerThresHold)}px`;
        }
      } else {
        if (header && showAppBanner) {
          header.classList.replace("sticky", "relative");
          appBanner.classList.replace("fixed", "relative");
          mainContent.style.marginTop = "0px";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAppBanner]);

  /**
   * This method is used to close the app redirection banner when click on cancel icon.
   */
  const onHandleCloseBannerClick = () => {
    setShowAppBanner(false);
    const header = document.getElementById("header");
    if (header) {
      header.classList.replace("relative", "sticky");
      CookieUtil.setCookie(IS_HIDE_APP_BANNER, "true", {
        maxAge: 60 * 60 * 24,
        path: "/",
        encode: decodeURIComponent,
        secure: true,
      });
    }
  };

  return (
    <>
      {showAppBanner && (
        <div id="appBanner" className="relative duration-100 ease-in-out" style={{ paddingTop: `${paddingTop}%` }}>
          <a href={appDeepLink}>
            <CustomImage
              src={imageUrl}
              alt="banner"
              width={imgWidth}
              height={imgHeight}
              isPriority={false}
              className="absolute left-0 top-0 object-contain"
            />
          </a>

          <CustomImage
            src="/icons/close.svg"
            alt="close icon"
            onClick={onHandleCloseBannerClick}
            tabIndex={0}
            width={20}
            height={20}
            className="absolute right-16 top-20"
            isPriority={false}
          />
        </div>
      )}
    </>
  );
};
AppRedirectionBanner.propTypes = {
  appBannerData: PropTypes.object.isRequired,
};

export default React.memo(AppRedirectionBanner);
