/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-boolean-value */
"use client";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import HTMLReactParser from "html-react-parser";
import Breadcrumb from "../../components/atoms/breadCrumb";
import { COUNTRIES, TESTIMONIAL_TITLE } from "../../constants/common/common";
import { getMicroSiteWidgetData } from "../../api/plpApi";
import { lazyLoadImgs, restrictAutoScrollingOnLoad, scrollToSection } from "../../utils/common";
import dynamic from "next/dynamic";
import PLPReviewRatings from "../../components/molecules/plpReviewRatings";
import { setCatalogId, setIsMobile } from "../../store/slices/common/appConfig";
import { useDispatch, useSelector } from "react-redux";
import { PageTypes, setPageType } from "../../utils/fnpPageType";
import { setIsMobileSearchVisibleAction } from "../../store/slices/commonSlice";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../analytics/clevertapConstants";
import useAnalytics from "../../analytics/useAnalytics";
import useCommonOmnitureSetup from "../../customHooks/omniture/useCommonOmnitureSetup";

const Widget = dynamic(() => import("../../components/organisms/widget"));
const CustomerReviewsCarousel = dynamic(() => import("../../components/organisms/customerReviewsCarousel"));
const CommonConditionalComponents = dynamic(() => import("../../components/molecules/commonConditionalComponents"), {
  ssr: false,
});
const OneTapLogin = dynamic(() => import("../../components/organisms/googleOneTapLogin"));

/**
 * MicroSite Component
 *
 * Renders a micro-site based on dynamic HTML data, breadcrumbs, widgets, and reviews.
 * The component asynchronously fetches widget data and replaces specific elements
 * within the parsed HTML content with React components like ReviewRatings and Widgets.
 *
 * @component
 *
 * @param {Object} props
 * @param {string} props.htmlData - The HTML content to be parsed and rendered.
 * @param {string} [props.headerName] - The name of the header, which is used as a meta property.
 * @param {number} [props.categoryRatingValue] - The overall rating value for the category.
 * @param {Object} [props.reviewResponse] - The response object containing customer reviews.
 * @param {Array<Object>} [props.breadcrumbs] - An array of breadcrumb objects, each representing a breadcrumb item.
 * @param {string} [props.widgets] - A stringified JSON containing widget configurations.
 * @param {string} [props.categoryId] - The ID of the current category being displayed.
 * @param {boolean} [props.isMobile] - Flag to indicate if the component is being viewed on a mobile device.
 *
 * @returns {JSX.Element} The rendered micro-site component.
 */
export const MicroSite = ({
  htmlData,
  headerName,
  categoryRatingValue,
  reviewResponse,
  breadcrumbs,
  widgets,
  categoryId,
  isMobile,
  catalogId,
  isShowSchema = true,
  slugName = "",
}) => {
  const [proDataMap, setProDataMap] = useState({});
  const [previousPathName, setPreviousPathName] = useState("");
  const checkLoginStatus = useSelector((state) => state.fus.loginDetail?.loggedin);
  const dispatch = useDispatch();
  const { init, trackEvent } = useAnalytics();
  const pathname = typeof window !== "undefined" && window.location.pathname ? window.location.pathname : "";
  const completePathName = typeof window !== "undefined" && window.location.href ? window.location.href : "";

  const micrositePageViewedData = {
    complete_url: completePathName,
    clean_url: pathname,
    internal_source_type: previousPathName,
    internal_source: previousPathName,
    page_name: cleverTapCommonWords.Microsite,
    page_type: cleverTapCommonWords.Microsite,
    login_status: checkLoginStatus,
    is_international: catalogId !== COUNTRIES.INDIA,
    plp_category: headerName,
    plp_rating: Number(categoryRatingValue) === 0 ? null : categoryRatingValue,
    plp_review_count: reviewResponse?.total,
    is_pill_available: false,
    is_banner_available: false,
    is_filter_available: false,
    is_microsite: true,
  };

  useEffect(() => {
    restrictAutoScrollingOnLoad();
    const { referrer } = document;
    if (referrer) {
      setPreviousPathName(new URL(referrer).pathname);
    }
    lazyLoadImgs();
    dispatch(setIsMobileSearchVisibleAction(false));
    init();
  }, []);

  useEffect(() => {
    if (checkLoginStatus !== undefined) {
      setTimeout(() => {
        trackEvent(cleverTapCommonEvents.micrositePageViewed, micrositePageViewedData, ["clevertap"]);
      }, 2000);
    }
  }, [checkLoginStatus]);

  useEffect(() => {
    /**
     * set fnp page type for product listing page
     */
    setPageType(completePathName.includes("-lp") ? PageTypes.CATEGORY : PageTypes.INFO);

    const widgetsData = widgets && JSON.parse(widgets);

    if (widgetsData?.length) {
      /** Fetches data for each widget and updates state */
      const fetchWidgetData = async () => {
        const proDataMap = {};

        for (const widget of widgetsData) {
          const proData = await getMicroSiteWidgetData(widget?.productIds?.toString());
          proDataMap[widget.divId] = proData;
        }

        setProDataMap(proDataMap);
      };

      fetchWidgetData();
    }
    dispatch(setCatalogId(catalogId));
    dispatch(setIsMobile(isMobile));
  }, [widgets]);

  useCommonOmnitureSetup();

  /**
   * This method provides a scroll behavior
   * on click of the global Review Component
   * which in scrolls down to the What customers
   * are saying component
   */
  const onScrollToReviews = () => {
    scrollToSection("reviews-section", 148);
  };

  /**
   * Parses and manipulates the `htmlData` prop to replace certain elements
   * with custom React components like ReviewRatings, CustomerReviewsCarousel, and Widget.
   *
   * @function
   *
   * @returns {JSX.Element|string} - The parsed and replaced HTML content.
   *
   * @example
   * const parsedContent = createMarkUp();
   */
  const createMarkUp = () => {
    const parseOptionsNode = {
      replace: (node) => {
        if (node.attribs && node.attribs.id === "categoriesRating") {
          if (categoryRatingValue && reviewResponse) {
            return (
              <PLPReviewRatings
                bestRating={categoryRatingValue}
                reviewCount={reviewResponse?.total}
                onScrollToReviews={onScrollToReviews}
              />
            );
          }
          return null;
        }
        if (node.attribs && node.attribs.id === "customersReviews") {
          if (reviewResponse?.data?.length) {
            return (
              <div className="flex flex-wrap justify-start gap-16">
                <CustomerReviewsCarousel
                  reviews={reviewResponse.data}
                  title={TESTIMONIAL_TITLE}
                  totalReviews={reviewResponse?.total}
                  pageType={typeof window !== "undefined" ? window.fnpPageType : ""}
                  categoryId={categoryId}
                  isMobile={isMobile}
                  viewAllStyle="px-16 md:px-0"
                />
              </div>
            );
          }
          return null;
        }
        if (widgets && node.attribs && node.attribs.id) {
          const matchingWidget = JSON.parse(widgets).find((widget) => widget.divId === node.attribs.id);
          if (matchingWidget && proDataMap[matchingWidget.divId]) {
            return (
              <>
                <Widget
                  widgetTitle={matchingWidget?.title}
                  productsList={proDataMap[matchingWidget.divId]?.data}
                  isMobile={isMobile}
                  isShowEarliestDate={false}
                  pageType={PageTypes?.CATEGORY}
                  titleStyle="pl-0"
                />
              </>
            );
          }
        }
        return node;
      },
    };
    return HTMLReactParser(htmlData, parseOptionsNode);
  };

  /**
   * Renders the main content of the micro-site, including breadcrumbs and the parsed HTML content.
   *
   * @function
   *
   * @returns {JSX.Element} - The rendered micro-site content, including breadcrumbs and widgets.
   *
   * @example
   * const content = renderContent();
   */
  const renderContent = () => {
    return (
      <>
        {breadcrumbs && !isMobile && !slugName?.includes("-lp") && (
          <Breadcrumb items={breadcrumbs} catalogId={catalogId} isShowSchema={isShowSchema} isFromMicrosite={true} />
        )}
        <div id="microSiteContent" className="leading-none">
          {createMarkUp()}
        </div>
        {breadcrumbs && isMobile && (
          <Breadcrumb items={breadcrumbs} catalogId={catalogId} isShowSchema={isShowSchema} isFromMicrosite={true} />
        )}
      </>
    );
  };

  return (
    <>
      <div itemScope itemType="//schema.org/Service" id="mainContent">
        <OneTapLogin />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <meta itemProp="serviceType" content="Gifting" />
        <div
          {...(isShowSchema && {
            itemType: "https://schema.org/Product",
            itemScope: true,
          })}
        >
          {!!headerName && (
            <span itemProp="name" style={{ display: "none" }}>
              {headerName}
            </span>
          )}
          {renderContent()}
        </div>
        <CommonConditionalComponents isMobile={isMobile} />
      </div>
    </>
  );
};

MicroSite.propTypes = {
  htmlData: PropTypes.string.isRequired,
  headerName: PropTypes.string,
  categoryRatingValue: PropTypes.number,
  reviewResponse: PropTypes.object,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      href: PropTypes.string,
      svg: PropTypes.bool,
    }),
  ),
  widgets: PropTypes.string,
  categoryId: PropTypes.string,
  isMobile: PropTypes.bool,
  catalogId: PropTypes.string.isRequired,
  slugName: PropTypes.string,
  isShowSchema: PropTypes.bool,
};
